import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  .title {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .list {
    display: flex;

    .social {
      margin-right: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon {
        opacity: .7;
        transition: opacity 300ms;
      }

      .text {
        margin-left: 10px;
        color: #8b8c91;
        display: none;

        @media ${device.tablet} {
          display: block;
        }
      }

      &:hover {
        .icon {
          opacity: 1;
        }

        .text {
          color: #000;
        }
      }
    }
  }

  &.small {
    .list {
      .social {
        margin-right: 10px;
        .text {
          display: none;
        }
      }
    }
  }
`;