import React from 'react';
import csx from 'classnames';

import { Imgix, truncate, parseDate } from '../../utils';

import trads from './trads';

import { Wrapper, Cover, Description } from './Item.styles';
import { useLocale } from '../../services/i18n/useLocale';

const imgResponsive = {
  xs: 12,
  lg: 10,
  contained: true,
};

const imgResponsiveSmall = {
  xs: 12,
  lg: 4,
  contained: true,
};

const ArticleItem = ({
  article,
  small = false,
  preloadImage = 'lazy',
  subLevel = false,
}) => {
  const intl = useLocale();
  const [hover, setHover] = React.useState(false);
  const setIsHover = React.useCallback(() => setHover(true), []);
  const setNotHover = React.useCallback(() => setHover(false), []);

  let image = article?.image;
  let imageMobile = article?.imageMobile;

  if (image && !image?.alt) {
    image.alt = article?.title;
  }
  const content = article?.text;

  const hasMobileImage = !!imageMobile?.url && imageMobile?.url !== image?.url;

  // console.log('???', article);
  return (
    <Wrapper
      className={csx({
        small,
      })}
      href={article?.url}
      onMouseEnter={setIsHover}
      onMouseLeave={setNotHover}
    >
      <Cover>
        {!hasMobileImage ? (
          <Imgix
            {...image}
            sizes={small ? imgResponsiveSmall : imgResponsive}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
            }}
            loading={preloadImage}
          />
        ) : (
          <Imgix
            sizes={small ? imgResponsiveSmall : imgResponsive}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
            }}
            loading={preloadImage}
            breakpoints={{
              0: imageMobile,
              760: image,
            }}
          />
        )}
      </Cover>
      <Description>
        {!!article?.date && (
          <time dateTime={article?.date} className="date">
            {parseDate(article?.date)?.toLocaleDateString(intl.locale)}
          </time>
        )}
        {subLevel ? (
          <div className="title">{article?.title}</div>
        ) : (
          <h2 className="title">{article?.title}</h2>
        )}

        {content ? (
          <summary className="excerpt">{truncate(content, 300)}</summary>
        ) : null}
        <div className={`simple-link red more ${hover ? 'hover' : ''}`}>
          {intl.formatMessage(trads.viewMore)}
        </div>
      </Description>
    </Wrapper>
  );
};

export default ArticleItem;
