import styled from 'styled-components';
import { ArticleItemWrapper } from '../../components/articles';

import { device } from '../../utils';

export const Wrapper = styled.article`
  width: 100%;
  display: block;

  .grey {
    color: #8b8c91;
  }
 
  .cover {
    .image {
      min-height: 250px;
      max-height: 500px;
    }
  }

  .list {
    margin-bottom: 60px;
    
    & > a:last-child {
      margin-bottom: 0;
    }
  }

  ${ArticleItemWrapper} {
    @media ${device.tablet} {
      margin: 60px 0;
    }
  }
`;

export const Header = styled.header`
  margin-top: 50px;
  margin-bottom: 20px;

  .bigtitle {
    font-weight: bold;
  }
`;
