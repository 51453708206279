import React from 'react';
import csx from 'classnames';

import { Icon } from '../../components/icons';

import Facebook from '../../icons/facebook-mask.svg';
import Twitter from '../../icons/twitter.svg';
import Linkedin from '../../icons/linkedin-filled.svg';

import trads from './trads';

import { Wrapper } from './Share.style';
import { useLocale } from '../../services/i18n/useLocale';

const shares = [
  {
    icon: Twitter,
    text: 'Twitter',
    url: 'https://twitter.com/intent/tweet?url=',
  },
  {
    icon: Linkedin,
    text: 'Linkedin',
    url: 'https://www.linkedin.com/shareArticle?mini=true&url=',
  },
  {
    icon: Facebook,
    text: 'Facebook',
    url: 'https://www.facebook.com/sharer.php?u=',
  },
];

const socialWindow = (url) => {
  const left = (window.screen.width - 570) / 2;
  const top = (window.screen.height - 570) / 2;
  const params =
    'menubar=no,toolbar=no,status=no,width=570,height=570,top=' +
    top +
    ',left=' +
    left;
  window.open(
    `${url}${encodeURIComponent(window.location.toString())}`,
    'Share',
    params
  );
};

const Share = ({ small }) => {
  const intl = useLocale();
  const titleText = intl.formatMessage(trads.shareTitle);
  const title = small ? (
    <div className="grey title">{titleText}</div>
  ) : (
    <div className="small-title">{titleText}</div>
  );

  return (
    <Wrapper
      className={csx({
        small,
      })}
    >
      {title}
      <div className="list">
        {shares.map((share) => (
          <button
            className="clear social"
            key={share.text}
            onClick={() => {
              socialWindow(share.url);
            }}
          >
            <Icon src={share.icon} height={22} />
            <span className="text">
              {intl.formatMessage(trads.sharePrefix)} {share.text}
            </span>
          </button>
        ))}
      </div>
    </Wrapper>
  );
};

export default Share;
