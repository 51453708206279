import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import { SiteInfos } from '../../layout';
import { getProductUrl } from '../../utils';
import { useLocale } from '../../services/i18n/useLocale';
import { JASOReformatViscosity } from '../../utils/jaso';

const excludeTitle = 'Default Title';

const ProductSnippet = ({ product, review }) => {
  const site = useContext(SiteInfos);
  const intl = useLocale();
  const { siteUrl } = site;

  const productPath = getProductUrl(
    product.handle,
    intl.locale,
    product.defaultVariant
  );
  const productUrl = `${siteUrl}${productPath}`;

  const currentViscosity = product?.defaultVariant?.viscosity;
  const jasoCurrentViscosity = JASOReformatViscosity(currentViscosity);
  const gtin = product?.defaultVariant?.barcode;

  const microdata = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: product.title,
    description: product.description,
    url: productUrl,
    sku: product.defaultVariant?.sku,
    gtin,
    image: [
      product.thumbnail?.originalSrc,
      ...product.commonImages.map((i) => i?.fluid?.src),
    ].filter((i) => i),
    brand: {
      '@type': 'Brand',
      name: 'Ipone',
    },
    offers: product.variants
      .filter((v) => !v.viscosity || v.viscosity === currentViscosity)
      .map((v) => {
        const urlArgs = v.selectedOptions
          .filter((o) => o.value !== excludeTitle)
          .filter((o) => o.value && o.value !== v.viscosity)
          .map((o) => `${o.name}=${encodeURIComponent(o.value)}`)
          .join('&');

        return {
          '@type': 'Offer',
          sku: v.sku,
          gtin: v.barcode,
          itemCondition: 'https://schema.org/NewCondition',
          availability: `https://schema.org/${v.availableForSale ? 'InStock' : 'OutOfStock'
            }`,
          name: JASOReformatViscosity(`${(product.title || '').replace(jasoCurrentViscosity, '')} ${v.title !== excludeTitle ? v.title : ''
            }`.trim()),
          price: parseFloat(v.price) || 0,
          priceCurrency: 'EUR',
          url: `${[getProductUrl(product.handle, intl.locale, v), urlArgs]
            .filter((item) => item)
            .join('?')}`,
        };
      }),
  };

  if (review && review.starsAverage >= 4) {
    microdata.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: review.starsAverage,
      reviewCount: review.total,
    };
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(microdata)}</script>
    </Helmet>
  );
};

export default ProductSnippet;
