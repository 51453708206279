import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../../components/seo';

import ArticleHomeScreen from '../../screens/articles/Home';
import { transformArticle, transformPageV2 } from '../../utils/Article';

const ArticleTemplate = ({ data }) => {
  const page = data?.prismicHomearticle;
  const articles = (data?.allPrismicArticles?.nodes || []).map(transformArticle);

  const newPages = (data?.allPrismicTuto?.nodes).map(transformPageV2);
  const pool = articles.concat(newPages).sort((a, b) => new Date(b.date) - new Date(a.date));
  console.log('articles', data?.allPrismicArticles?.nodes);
  console.log('newPages', data?.allPrismicTuto?.nodes);

  console.log('new format', newPages);
  console.log('new articles', articles);

  const lastArticles = articles.length ? articles[0] : undefined;

  return (
    <>
      <Seo
        title={page.data?.title}
        description={lastArticles?.data?.content?.text}
        image={lastArticles.data?.thumbnail?.url}
      />
      <ArticleHomeScreen
        {...page?.data}
        articles={pool}
      />
    </>
  );
};

export const query = graphql`
  query ArticlesHome($prismic: ID!, $cmsLocaleGlob: String!) {
    prismicHomearticle(
      prismicId: {eq: $prismic }
    ) {
      data {
        title
        cover {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
    allPrismicArticles(
      filter: {lang: { glob: $cmsLocaleGlob }},
      sort: {fields: first_publication_date, order: DESC}
    ) {
      nodes {
        date: first_publication_date
        url
        data {
          title
          thumbnail {
            alt
            url
            dimensions {
              height
              width
            }
          }
          date
          cover {
            alt
            url
            dimensions {
              height
              width
            }
          }
          body {
            ... on PrismicArticlesDataBodyContenu {
              id
              slice_type
              primary {
                content {
                  text
                }
              }
            }
          }
        }
      }
    }
    allPrismicTuto(
      filter: {tags: {in: "actu"}, lang: { glob: $cmsLocaleGlob }}
      sort: {fields: first_publication_date, order: DESC}
    ) {
      nodes {
        date: first_publication_date
        url
        data {
          cover {
            alt
            url
            dimensions {
              height
              width
            }
          }
          cover_mobile {
            alt
            url
            dimensions {
              height
              width
            }
          }
          title {
            text
          }
          subtitle
          body {
            ... on PrismicTutoDataBodyContent {
              id
              slice_type
              items {
                content {
                  text
                }
              }
              primary {
                section_title
              }
            }
          }
        }
      }
    }
  }
`;
export default ArticleTemplate;