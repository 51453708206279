import styled from 'styled-components';

import { device } from '../../utils';
import MagicLink from '../link';

export const Cover = styled.div`
  background-color: #fafafa;

  @media ${device.laptop} {
    grid-column: 1 / span 2;
  }

  

  .gatsby-image-wrapper {
    height: 100%;
    max-height: 450px;
    max-width: 100%;
    width: 100%;

    @media ${device.tablet} {
      aspect-ratio: 1.8;
    }

    @media ${device.laptop} {
      aspect-ratio: 2.033;
    }
  }
`;

export const Description = styled.div`
  padding: 30px 20px;
  background-color: #fafafa;
  color: #111111;
  font-size: 13px;

  transition: background-color 400ms ease-out;

  .date {
    display: block;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .title {
    font-family: 'Montserrat';
    font-weight: 900;
    text-transform: uppercase;
    font-size: 20px;
    color: #111111;
    margin: 0px 0 10px;
  }

  .excerpt {
    line-height: 1.4em;
    margin-bottom: 15px;
    list-style: none;
  }

  .simple-link {
    display: inline-block;
  }

  @media ${device.laptop} {
    font-size: 14px;
    padding: 40px 30px;

    .title {
      font-size: 20px;
      margin: 0 0 20px 0;
    }

    .excerpt {
      font-size: 16px;
    }

    .date {
      margin-bottom: 20px;
    }
  }
`;

export const Wrapper = styled(MagicLink)`
  display: grid;
  grid-template-columns: 1fr;
  margin: 30px 0;

  @media ${device.tablet} {
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 400px;
  }

  &.small {
    grid-gap: 0;
    grid-template-columns: 1fr;
    margin: 30px 0;

    @media ${device.tablet} {
      grid-template-columns: 1fr 1fr;
    }

    @media ${device.laptop} {
      grid-template-columns: 50% 0 50%;
    }

    ${Description} {
      .title {
        font-size: 22px;
      }
    }
  }

  &:hover {
    ${Description} {
      background-color: #f2f2f2;
    }
  }
`;
