export const transformArticle = (article) => ({
  date: article.data.date || article.date,
  url: article.url,
  title: article.data?.title,
  image: article?.data?.thumbnail?.url ? article?.data?.thumbnail : article?.data?.cover,
  imageMobile: article?.data?.thumbnail,
  text: article?.data?.body
    ?.filter(s => s.slice_type === 'contenu')
    ?.filter(s => s?.primary?.content?.text)
    ?.map(s => s?.primary?.content?.text)
    ?.shift()
});

export const transformPageV2 = (newPage) => ({
  date: newPage.date,
  url: newPage.url,
  title: newPage.data?.title?.text || newPage?.data?.pageTitle?.text || newPage.data?.subtitle,
  image: newPage?.data?.cover,
  imageMobile: newPage?.data?.cover_mobile,
  text: newPage.data?.subtitle || newPage?.data?.body
    ?.filter(s => s.slice_type === 'content')
    ?.filter(s => s.items.length)
    ?.map(s => s.items[0].content?.text)
    ?.shift()
});