import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { SiteInfos } from '../../layout';

const BreadcrumbSnippet = ({ list=[] }) => {
  const site = useContext(SiteInfos);
  const { siteUrl } = site;

  const microdata = {
    '@context': 'http://schema.org/',
    '@type': 'BreadcrumbList',
    'itemListElement': list.map((item, idx) => ({
      '@type': 'ListItem',
      'position': idx + 1,
      'name': item.name,
      'item': `${siteUrl}${item.url || ''}`
    }))
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(microdata)}</script>
    </Helmet>
  );
}

export default BreadcrumbSnippet;
