import React from 'react';
import { ArticleItem } from '../../components/articles';

import { nl2br, Imgix } from '../../utils';

import {
  Wrapper,
  Header,
} from './Home.style';

const HomeArticles = ({
  title,
  cover,
  articles = [],
}) => {
  return (
    <Wrapper>
      <div className="cover">
        <Imgix
          {...cover}
          loading="eager"
          preloadHtml
          fadeIn={false}

        />
      </div>
      <div className="container">
        <Header>
          <h1 className="bigtitle">{nl2br(title)}</h1>
        </Header>
        <div className="list">
          {articles.map((a, idx) => (
            <ArticleItem
              key={a.url}
              article={a}
              preloadImage={idx === 0 ? 'eager' : 'lazy'}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default HomeArticles;